import { GetStaticPaths, GetStaticProps } from 'next'

import Family from '@/screens/Family'
import { GetFamilyPageDocument } from '@/queries/queries/Family/getFamiliyPage.generated'
import { defaultGetStaticPathsFallback, defaultGetStaticProps, getStaticPathForPages } from '@/utils/pageUtils'

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: getStaticPathForPages([
      '',
      'defence-and-security',
      'dredging-and-dredging-equipment',
      'ferries',
      'offshore',
      'tugs',
      'tugs/asd-tugs',
    ]).paths,
    fallback: defaultGetStaticPathsFallback,
  }
}

export const getStaticProps: GetStaticProps = async (context) =>
  defaultGetStaticProps('vessels', context, { query: GetFamilyPageDocument }, (data) => data.familyPage == null)

export default Family
